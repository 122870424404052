import React from 'react'
import LeadsForAdmin from '../../components/leadsforAdmin'

const AllLeads = () => {
  return (
    <div>
     <LeadsForAdmin />
    </div>
  )
}

export default AllLeads